import rules from '@/mixins/rules'
export default {
  mixins: [rules],
  model: {
    prop: 'value',
    event: 'input',
  },
  props: ['value'],
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      },
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
  },
}
